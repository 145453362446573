import React from "react";
import { Helmet } from "react-helmet";
import { Link, useStaticQuery, graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import GreenCheckMark from '../components/icons/GreenCheckMark'
import Header from "../components/Header";
import GreenPlusIcon from '../components/icons/GreenPlusIcon'
import Footer from "../components/Footer";
import ChevronRight from '../components/icons/Approach/ChevronRight'
import "../../scss/main.scss";
import AdditionalIcon from "../components/icons/Pricing/AdditionalIcon";
import { PostcodeCTAInput } from '../components/PostcodeCTA/PostcodeCTA'
import { COUPLE_TIER_ONE, COUPLE_TIER_THREE, COUPLE_TIER_TWO, SINGLE_TIER_ONE, SINGLE_TIER_THREE, SINGLE_TIER_TWO } from "../constants/pricing";

export default function Pricing() {
    const pageData = useStaticQuery(graphql`
        query PricingQuery {
            site {
                siteMetadata {
                    featureToggles {
                        enableBlogContent
                    }
                }
            }
        }
    `)

    const { site: { siteMetadata: {
        featureToggles: {
            enableBlogContent,
        }
    } } } = pageData

	return (
		<>
			<Helmet>
				<meta charset="UTF-8" />
				<meta http-equiv="X-UA-Compatible" content="IE=edge" />
				<meta name="viewport" content="width=device-width, initial-scale=1.0" />
				<title>Edyn Care | Pricing</title>
				<meta property="og:title" content="Edyn Care | Pricing"/>
				<meta property="og:description" content="High quality care in the comfort of your own home."/>
				<meta property="og:image" content="https://www.edyn.care/assets/marketing-site/images/open-graph-img.png"/>
				<meta property="og:url" content="https://www.edyn.care/pricing" />

				<meta name="twitter:card" content="summary_large_image" />
				<meta property="twitter:domain" content="edyn.care" />
				<meta property="twitter:url" content="https://www.edyn.care/pricing" />
				<meta name="twitter:title" content="Edyn Care | Pricing" />
				<meta name="twitter:description" content="High quality care in the comfort of your own home." />
				<meta name="twitter:image" content="https://www.edyn.care/assets/marketing-site/images/open-graph-img.png" />
			</Helmet>
			<div className="container">
				<Header />
				<main className='main-content'>
					<section>
						<div className="pricing-landing">
							<StaticImage placeholder="blurred"
								className="pricing-header-img"
								src="../../static/assets/marketing-site/images/pricing-header-img.png"
								alt="Pricing Header image"
							/>
							<h1>Our pricing</h1>
							<StaticImage placeholder="blurred"
								className="pricing-header-pattern large"
								src="../../static/assets/marketing-site/images/pricing-header-pattern.svg"
								alt="SVG pattern"
							/>
							<StaticImage placeholder="blurred"
								className="pricing-header-pattern mobile" 
								src="../../static/assets/marketing-site/images/pricing-header-pattern-compact.svg"
							/>
						</div>
					</section>

					<section className='pricing-section-tiers'>
						<div className="three-tiers">
							<div className="three-tiers-left">
								<h3>
									We bracket our care packages into three different tiers{" "}
								</h3>
								<p className="primary">
									Our Care Advisors and Care Managers work with you and your
									family to ensure your family receives the right level of
									support. This is a collaborative process to ensure both the
									safety of your loved one and that you only pay for the support
									your family needs.
								</p>
								<p className="primary">
									Speak with one of our care managers to understand which tier
									is best suited to your situation.
								</p>
								<Link to="#pricing-section-tier-details">
									<button className="btn-primary desktop_view">
										View the details of each tier
									</button>
								</Link>
							</div>
							<div className="three-tiers-right">
								<ul>
									<li className="tier-1">
										<h3>Tier 1:</h3>
										<p className="secondary">
											For those who need some assistance with daily active
											tasks.
										</p>
										<p className="three-tiers-price">From {SINGLE_TIER_ONE} per week</p>
									</li>
									<li className="tier-2">
										<h3>Tier 2:</h3>
										<p className="secondary">
											For those with conditions such as dementia, parkinsons and
											challenging requirements, like catheter and stoma.
										</p>
										{/* <p className="three-tiers-price three-tiers-price--hide">From £1095 per week</p> */}
										<p className="three-tiers-price" >From {SINGLE_TIER_TWO} per week</p>
									</li>
									<li className="tier-3">
										<h3>Tier 3:</h3>
										<p className="secondary">
											Those in need of significant care, throughout the day.
										</p>
										{/* <p className="three-tiers-price three-tiers-price--hide">From £1550 per week</p> */}
										<p className="three-tiers-price">From {SINGLE_TIER_THREE} per week</p>
									</li>
									<Link to="#pricing-section-tier-details" className="mobile_view">
										<button className="btn-primary">
											View the details of each tier
										</button>
									</Link>
								</ul>
							</div>
						</div>
					</section>

					<section className='pricing-section-contact-us-banner'>
						<div className="contact-us-banner">
							<StaticImage placeholder="blurred"
								className="pricing-postcode-people"
								src="../../static/assets/marketing-site/images/pricing-postcode-people.png"
								alt="Pricing People"
								objectFit="contain"
							/>
							<div className="contact-us-text">
								<p>
									Enter your postcode or speak with a friendly care advisor:
								</p>
								<h3>
									<a href="tel:020 3970 9900">020 3970 9900</a>
								</h3>
							</div>
							<div className="post-input-custom">
								<PostcodeCTAInput />
							</div>
						</div>
					</section>

					<section id="pricing-section-tier-details" className='pricing-section-tier-details'>
						<div className="pricing-tier-details">
							<h3>A detailed view of our tiers</h3>
							{/*   PRICING TIER CARDS  */}
							<div className="pricing-tier-card tier-1-details">
								<div className="card-left">
									<div className="card-context">
										<p className="secondary rounded green"> Tier one </p>
										<br />
										<StaticImage placeholder="blurred"
											src="../../static/assets/marketing-site/images/team/tilda.png"
											alt="Tier 1 Person"
										/>
										<h1>Standard live-in care</h1>
										<br />
										<p className="secondary small">
											Those who need some support with daily active tasks such
											as household help and assistance around the home, as well
											as companionship.
										</p>
									</div>
									<div className="tier-discount wide-view">
										<ul>
											<li>
												<div className="tier-guys">
													<StaticImage placeholder="blurred"
														src="../../static/assets/marketing-site/images/little-tier-guy.svg"
														alt="Tier Guy"
													/>
												</div>
												<div className="tier-pricing">
													<p>Care for one person : </p>
													<p className="tier-discount-price"> {SINGLE_TIER_ONE} per week </p>
												</div>
											</li>
											<li>
												<div className="tier-guys">
													<StaticImage placeholder="blurred" src="../../static/assets/marketing-site/images/little-tier-guys.svg" />
												</div>
												<div className="tier-pricing">
													<p>Care for two people : </p>
													<p className="tier-discount-price">
														{" "}
														{COUPLE_TIER_ONE} per week{" "}
													</p>
												</div>
											</li>
										</ul>
									</div>
								</div>
								<div className="card-right">
									<div className="tier-includes">
										<h3>This package includes the following:</h3>
										<ul>
											<li>
												<GreenCheckMark className="gatsby-image-wrapper"/>
												<p>Household tasks </p>
											</li>
											<li>
												<GreenCheckMark className="gatsby-image-wrapper"/>
												<p>Medication support</p>
											</li>
											<li>
												<GreenCheckMark className="gatsby-image-wrapper"/>
												<p>
													Personal hygiene including assistance with dressing
													and toileting.
												</p>
											</li>
											<li>
												<GreenCheckMark className="gatsby-image-wrapper"/>
												<p>Companionship and emotional support</p>
											</li>
											<li>
												<GreenCheckMark className="gatsby-image-wrapper"/>
												<p>
													Some night-time care, typically a twice a night or
													less
												</p>
											</li>
										</ul>
									</div>
								</div>
								<div className="tier-discount mobile">
									<ul>
										<li>
											<div className="tier-guys">
												<StaticImage placeholder="blurred"
													src="../../static/assets/marketing-site/images/little-tier-guy.svg"
													alt="Tier Guy"
												/>
											</div>
											<div className="tier-pricing">
												<p>Care for one person : </p>
												<p className="tier-discount-price"> {SINGLE_TIER_ONE} per week </p>
											</div>
										</li>
										<li className="divider green"></li>
										
										<li>
											<div className="tier-guys">
												<StaticImage placeholder="blurred" src="../../static/assets/marketing-site/images/little-tier-guys.svg" />
											</div>
											<div className="tier-pricing">
												<p>Care for two people : </p>
												<p className="tier-discount-price"> {COUPLE_TIER_ONE} per week </p>
											</div>
										</li>
									</ul>
								</div>
							</div>

							<div className="pricing-tier-card tier-2-details">
								<div className="card-left">
									<div className="card-context">
										<p className="secondary rounded blue"> Tier two </p>
										<br />
										<StaticImage placeholder="blurred"
											src="../../static/assets/marketing-site/images/oli-replacement.png"
											alt="Tier 1 Person"
										/>
										<h1>Specialist live-in care</h1>
										<br />
										<p className="secondary small">
											Those who need some support with daily active tasks such
											as household help and assistance around the home, as well
											as companionship.
										</p>
									</div>
									<div className="tier-discount wide-view">
										<ul>
											<li>
												<div className="tier-guys">
													<StaticImage placeholder="blurred"
														src="../../static/assets/marketing-site/images/little-tier-guy.svg"
														alt="Tier Guy"
													/>
												</div>
												<div className="tier-pricing">
													<p>Care for one person : </p>
													<p className="tier-discount-price">
														{" "}
														{SINGLE_TIER_TWO} per week{" "}
													</p>
												</div>
											</li>
											<li>
												<div className="tier-guys">
													<StaticImage placeholder="blurred" src="../../static/assets/marketing-site/images/little-tier-guys.svg" />
												</div>
												<div className="tier-pricing">
													<p>Care for two people : </p>
													<p className="tier-discount-price">
														{" "}
														{COUPLE_TIER_TWO} per week{" "}
													</p>
													{/* <p className="tier-discount-price call-for-a-quote">
														{" "}
														Call us for a quote {" "}
													</p> */}
												</div>
											</li>
										</ul>
									</div>
								</div>
								<div className="card-right">
									<div className="tier-includes">
										<h3>This package includes the following:</h3>
										<ul>
											<li>
												<GreenCheckMark className="gatsby-image-wrapper"/>
												<p>Household tasks </p>
											</li>
											<li>
												<GreenCheckMark className="gatsby-image-wrapper"/>
												<p>Medication support</p>
											</li>
											<li>
												<GreenCheckMark className="gatsby-image-wrapper"/>
												<p>
													More extensive personal hygiene, including help with
													stoma, peg or catheter
												</p>
											</li>
											<li>
												<GreenCheckMark className="gatsby-image-wrapper"/>
												<p>
													Hoisting - providing it can be completed by one person
												</p>
											</li>
											<li>
												<GreenCheckMark className="gatsby-image-wrapper"/>
												<p>
													Help with challenging behaviours, such as refusal to
													take medication
												</p>
											</li>
											<li>
												<GreenCheckMark className="gatsby-image-wrapper"/>
												<p>Carer breaks to work around the care schedule</p>
											</li>
										</ul>
									</div>
								</div>
								<div className="tier-discount mobile">
									<ul>
										<li>
											<div className="tier-guys">
												<StaticImage placeholder="blurred"
													src="../../static/assets/marketing-site/images/little-tier-guy.svg"
													alt="Tier Guy"
												/>
											</div>
											<div className="tier-pricing">
												<p>Care for one person : </p>
												<p className="tier-discount-price"> {SINGLE_TIER_TWO} per week </p>
											</div>
										</li>
										<li className="divider blue"></li>
										<li>
											<div className="tier-guys">
												<StaticImage placeholder="blurred" src="../../static/assets/marketing-site/images/little-tier-guys.svg" />
											</div>
											<div className="tier-pricing">
												<p>Care for two people : </p>
												<p className="tier-discount-price"> {COUPLE_TIER_TWO} per week </p>
												{/* <p className="tier-discount-price call-for-a-quote">
													Call us for a quote
												</p> */}
											</div>
										</li>
									</ul>
								</div>
							</div>

							<div className="pricing-tier-card tier-3-details">
								<div className="card-left">
									<div className="card-context">
										<p className="secondary rounded orange"> Tier three </p>
										<br />
										<StaticImage placeholder="blurred"
											src="../../static/assets/marketing-site/images/tier-3-person.png"
											alt="Tier 3 Person"
											objectFit='contain'
											className="enlarged"
										/>
										<h1>Advanced live-in care</h1>
										<br />
										<p className="secondary small">
											Those in need of significant care, throughout the day. Typically, for those with acute care needs, who require continuous and specialist assistance.
										</p>
									</div>
									<div className="tier-discount single wide-view">
										<ul>
											<li>
												<div className="tier-guys">
													<StaticImage placeholder="blurred"
														src="../../static/assets/marketing-site/images/little-tier-guy.svg"
														alt="Tier Guy"
													/>
												</div>
												<div className="tier-pricing">
													<p>Care for one person : </p>
													<p className="tier-discount-price">
														{" "}
														{SINGLE_TIER_THREE} per week{" "}
													</p>
													{/* <p className="tier-discount-price call-for-a-quote">
														Call us for a quote
													</p> */}
												</div>
											</li>
											<li>
												<div className="tier-guys">
													<StaticImage placeholder="blurred" src="../../static/assets/marketing-site/images/little-tier-guys.svg" />
												</div>
												<div className="tier-pricing">
													<p>Care for two people : </p>
													<p className="tier-discount-price">
														{" "}
														{COUPLE_TIER_THREE} per week{" "}
													</p>
													{/* <p className="tier-discount-price call-for-a-quote">
														{" "}
														Call us for a quote {" "}
													</p> */}
												</div>
											</li>
										</ul>
									</div>
								</div>
								<div className="card-right">
									<div className="tier-includes">
										<h3>This package includes the following:</h3>
										<ul>
											<li>
												<GreenCheckMark className="gatsby-image-wrapper"/>
												<p>
													Support with all Household tasks
												</p>
											</li>
											<li>
												<GreenCheckMark className="gatsby-image-wrapper"/>
												<p>
													Total support for all personal needs, including help with stoma, peg or catheter
												</p>
											</li>
											<li>
												<GreenCheckMark className="gatsby-image-wrapper"/>
												<p>
													Complex one-to-one care needs and support, including managing specific conditions.
												</p>
											</li>
											<li>
												<GreenCheckMark className="gatsby-image-wrapper"/>
												<p>
													Hoisting that requires more than one carer
												</p>
											</li>
											<li>
												<GreenCheckMark className="gatsby-image-wrapper"/>
												<p>
													Help with severely challenging behaviour, such as refusing support or meals
												</p>
											</li>
											<li>
												<GreenCheckMark className="gatsby-image-wrapper"/>
												<p>
													Carer breaks are designed to ensure there’s never a gap in support
												</p>
											</li>
										</ul>
									</div>
								</div>
								<div className="tier-discount single mobile">
									<ul>
										<li>
											<div className="tier-guys">
												<StaticImage placeholder="blurred"
													src="../../static/assets/marketing-site/images/little-tier-guy.svg"
													alt="Tier Guy"
												/>
											</div>
											<div className="tier-pricing">
												<p>Care for one person : </p>
												<p className="tier-discount-price"> {SINGLE_TIER_THREE} per week </p>
												{/* <p className="tier-discount-price call-for-a-quote">
														Call us for a quote
													</p> */}
											</div>
										</li>
										<li>
											<div className="tier-guys">
												<StaticImage placeholder="blurred" src="../../static/assets/marketing-site/images/little-tier-guys.svg" />
											</div>
											<div className="tier-pricing">
												<p>Care for two people : </p>
												<p className="tier-discount-price"> {COUPLE_TIER_THREE} per week </p>
												{/* <p className="tier-discount-price call-for-a-quote">
													Call us for a quote
												</p> */}
											</div>
										</li>
									</ul>
								</div>
							</div>
							{/*   END PRICING TIER CARD  */}

							<div className="additional-options">
								<div className="additional-options-left">
									<div className="title">
										<AdditionalIcon />
									</div>
									<div className="additional-options-content">
										<h1>
											Additional Options
										</h1>
										<p className="secondary pro">
											As well as deciding on your care package, you should also
											consider whether you want to add any of the following
											services to your family’s care package.
										</p>
									</div>
								</div>
								<div className="additional-options-right">
									<ul>
										<li>
											<GreenPlusIcon className="icon-default"/>
											<p>
												Carer with a car or driving licence: If you need a carer
												who can drive or bring a car with them - £40 - £80
												Weekly
											</p>
										</li>
										<li>
											<GreenPlusIcon className="icon-default"/>
											<p>
												Spousal support: if the carer is also helping another
												resident with light domestic duties - £100 weekly
											</p>
										</li>

										<li>
											<GreenPlusIcon className="icon-default"/>
											<p>
												Managed food budget: If you'd like us to help manage the
												carer's daily food budget - £40 weekly
											</p>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</section>
                    { enableBlogContent && (
                        <>
                            <hr className="pricing_hr"/>
                            <section className="info-block-pricing-page pricing">
                                <div className="paying-for-care">
                                    <div className="info-block-section left pricing">
                                        <h2>Paying for care</h2>
                                        <div className="info-block-content">
                                            <Link to="/blog/what-are-my-funding-options/" className="infoblock read-more green">
                                                <h3>What are my funding options?</h3>
												<span className="info-read" >
                                                    Read more
                                                    <ChevronRight />
												</span>
                                            </Link>
                                            <Link to="/blog/what-is-the-cost-of-live-in-care/" className="infoblock read-more green">
                                                <h3>What is the cost of live-in care?</h3>
												<span className="info-read" >
                                                    Read more
                                                    <ChevronRight />
                                                </span>
                                            </Link>
                                            <Link to='/blog/state-and-local-authority-funding/' className="infoblock read-more green">
                                                <h3>State and local authority funding</h3>
												<span className="info-read" >
                                                    Read more
                                                    <ChevronRight />
                                                </span>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <section className="info-block-pricing-page pricing">
                                <div className="paying-for-care">
                                    <div className="info-block-section left pricing bottom-padding top-no-spacing">
                                        <h2>Starting the conversation</h2>
                                        <div className="info-block-content">
                                            <Link to="/blog/when-is-care-needed/" className="infoblock read-more yellow">
                                                <h3>When is care needed?</h3>
												<span className="info-read" >
                                                    Read more
                                                    <ChevronRight />
                                                </span>
                                            </Link>
                                            <Link to="/blog/what-can-a-live-in-carer-help-with/" className="infoblock read-more yellow">
                                                <h3>What can a live-in carer help with?</h3>
												<span className="info-read" >
                                                    Read more
                                                    <ChevronRight />
                                                </span>
                                            </Link>
                                            <Link to="/blog/live-in-care-vs-care-home/" className="infoblock read-more yellow">
                                                <h3>Live-in care vs. Care Home</h3>
												<span className="info-read" >
                                                    Read more
                                                    <ChevronRight />
                                                </span>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </>
                    )}
				</main>
    			<Footer />
			</div>
			{/*   end of container  */}
		</>
	);
}
